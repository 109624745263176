<template>
    <div>
        <template v-if="item.created_by_user">
            <span>
                {{ item.created_by_user.name }}
            </span>
        </template>
        <template v-else>
            <span>
                Admin
            </span>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            required: true
        }
    }
};
</script>